.dashboard-image {
  max-width: 100%;
  max-height: 300px;
}

.dashboard-image-container {
  text-align: center;
}

.advertising-container {
  position: absolute;
  bottom: 10px;
}

.advertising-image {
  width: 250px;
}

.advertising-raika {
  text-align: right;
}

.dashboard-spacer {
  margin-top: 15px;
}

.dashboard-text {
  margin: 0px;
  font-family: consolas;
  text-transform: uppercase;
}

.florianihof-walzer {
  font-family: "Modak";
}
