.article-img-S {
  height: 250px;
}
.article-img-M {
  height: 300px;
}
.article-img-L {
  height: 350px;
}

.article-detail-img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.article-card {
  border-radius: 25px;
  // border: 2px solid;
  padding: 20px;
}
