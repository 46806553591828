//Suggested location to add your overrides so that migration would be easy by just updating the SASS folder in the future

.p-dialog {
  max-width: 90%;
  min-width: 90%;
}

.fa-icon-event {
  margin-right: 10px;
}

.p-card-header img {
  width: auto;
  max-width: 100%;
}

.p-datatable-wrapper {
  word-break: break-all;
}

.admin-label-right {
  text-align: right;
}

.admin-person-image {
  object-fit: contain;
  width: 50%;
  height: 50%;
}

.iframe-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.member-card {
  border-style: solid;
  border-radius: 25px;
  // margin-top: 5px;
  // margin-right: 10px;
  // margin-left: 5px;
  margin: 5px;
  padding: 10px;
  border-color: grey;
  border-width: 1px;
  background-color: #93ffc82e;
  font-size: 1.2em;
  font-family: monospace;
}

.member-image {
  object-fit: contain;
  width: 50%;
  height: 100px;
}

.member-description {
  word-break: break-all;
}

.member-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
  border-bottom: 1px solid #d9dad9;
}

.link-tooltip {
  margin-left: 5px;
}
